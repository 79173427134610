import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const lesentierduvillage = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Le sentier du village" />
    <h3 className='underline-title'>Le sentier du village</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ce sentier me revient à la vision<br />
        Il nous menait serpentant jusqu'au champ<br />
        S'enfouissait dans futaises et buissons<br />
        Zigzaguait dans prairies et savanes<br />
        Franchissait Motiambi l'intarissable<br />
        Continuel Motiambi anéantissait ses traces<br />
      </p>
      <p>
        Ce sentier me revient à la vision<br />
        Vêtu de<span className="font-italic">"wazara"</span> papa perçait ce sentier<br />
        Plongé dans l'herbe chargée d'aiguail<br />
        Sur le passage rassurant de papa<br />
        S'égrenaient les rosées cristallines<br />
      </p>
      <p>
        Ce sentier me revient à la vision<br />
        Intrépide mon père ouvrait ces broussailles<br />
        Et moi englouti dans l'herbe folle<br />
        J'emboitais la pas au défricheur hardi<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">
      Motiambi: ruisseau du village<br/>
      Wazara: feuilles sèches du bananier dont on se sert pour se protéger contre les rosées
      </p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default lesentierduvillage
